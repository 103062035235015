<template>
  <div class="options cias pd-20">
    <a-page-header
      class="mb-10"
      style="padding: 0"
      sub-title=""
      @back="() => $router.go(-1)"
    >
      <div slot="title">
        <h1>{{ productSupplier.id }} - Editar Fornecedor</h1>
      </div>
      <template slot="extra"> </template>
    </a-page-header>

    <a-form :form="form" @submit="handleSubmit">
      <a-row :gutter="[20, 20]">
        <a-col class="box box-contractor" :span="8">
          <a-row :gutter="20" class="actions mt-20" style="min-height: 570px">
            <h2 class="title mb-0">DADOS DO FORNECEDOR</h2>
            <div style="padding: 17px 10px; height: 630px; overflow: auto">
              <EditProductSupplierDefaultData
                :form="form"
                :edit="true"
                :showField="true"
              />
            </div>
          </a-row>
        </a-col>

        <a-col class="box box-contractor" :span="16">
          <a-row :gutter="20" class="actions mt-20" style="min-height: 570px">
            <a-row class="title-box mb-20" type="flex" justify="space-between">
              <a-col>
                <h3>DETALHES DO FORNECEDOR</h3>
              </a-col>
            </a-row>

            <a-tabs
              class="portal-tabs travel-tabs with-icons vertical payment-tabs"
              default-active-key="1"
              tab-position="left"
            >
              <a-tab-pane key="1" forceRender="true">
                <template slot="tab">
                  <a-tooltip>
                    <template slot="title"> Contatos </template>
                    <a-icon type="team" />
                  </a-tooltip>
                </template>

                <div>
                  <h3 class="h3 title mb-20">Contatos</h3>
                  <a-row
                    v-for="(contact, index) in contactRows"
                    :key="index"
                    :gutter="20"
                  >
                    <a-col :span="22">
                      <a-row :gutter="20">
                        <a-col :span="6">
                          <a-form-item>
                            <label :class="'filled'"> Nome </label>
                            <a-input
                              class="travel-input"
                              placeholder="Nome"
                              v-decorator="[
                                `contact_first_name_${contact.id}`,
                                {
                                  initialValue: contact.first_name,
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Obrigatório',
                                    },
                                  ],
                                },
                              ]"
                            >
                              <a-icon slot="prefix" type="field-svg" />
                            </a-input>
                          </a-form-item>
                        </a-col>

                        <a-col :span="6">
                          <a-form-item>
                            <label :class="'filled'"> Sobrenome </label>
                            <a-input
                              class="travel-input"
                              placeholder="Sobrenome"
                              v-decorator="[
                                `contact_last_name_${contact.id}`,
                                {
                                  initialValue: contact.last_name,
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Obrigatório',
                                    },
                                  ],
                                },
                              ]"
                            >
                              <a-icon slot="prefix" type="field-svg" />
                            </a-input>
                          </a-form-item>
                        </a-col>

                        <a-col :span="6">
                          <a-form-item>
                            <label :class="'filled'"> Nascimento </label>
                            <a-date-picker
                              class="travel-input"
                              placeholder="Nascimento"
                              format="DD/MM/YYYY"
                              v-mask="'##/##/####'"
                              :showToday="false"
                              v-decorator="[
                                `contact_birthday_${contact.id}`,
                                {
                                  initialValue: contact.birthday,
                                  rules: [
                                    {
                                      required: false,
                                      message: 'Obrigatório',
                                    },
                                  ],
                                },
                              ]"
                            >
                            </a-date-picker>
                          </a-form-item>
                        </a-col>

                        <a-col :span="6">
                          <a-form-item>
                            <label :class="'filled'"> E-mail </label>
                            <a-input
                              class="travel-input"
                              placeholder="E-mail"
                              v-decorator="[
                                `contact_email_${contact.id}`,
                                {
                                  initialValue: contact.email,
                                  rules: [
                                    {
                                      type: 'email',
                                      message: 'Escreva um e-mail válido',
                                    },
                                  ],
                                },
                              ]"
                            >
                              <a-icon slot="prefix" type="field-svg" />
                            </a-input>
                          </a-form-item>
                        </a-col>

                        <a-col :span="6">
                          <a-form-item>
                            <label :class="'filled'"> Telefone </label>
                            <a-input
                              class="travel-input"
                              placeholder="Telefone"
                              v-mask="['(##) # ####-####', '(##) ####-####']"
                              v-decorator="[
                                `contact_phone_${contact.id}`,
                                {
                                  initialValue: contact.phone,
                                },
                              ]"
                            >
                              <a-icon slot="prefix" type="field-svg" />
                            </a-input>
                          </a-form-item>
                        </a-col>

                        <a-col :span="6">
                          <a-form-item class="travel-input-outer">
                            <label :class="'filled'">Cargo</label>
                            <a-select
                              class="travel-input"
                              show-search
                              placeholder="Cargo"
                              v-decorator="[
                                `contact_job_${contact.id}`,
                                {
                                  initialValue: contact.job,
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Obrigatório',
                                    },
                                  ],
                                },
                              ]"
                            >
                              <a-select-option
                                v-for="(item, index) of jobs"
                                :key="index"
                                :value="item.label"
                              >
                                {{ item.label }}
                              </a-select-option>
                            </a-select>
                          </a-form-item>
                        </a-col>

                        <a-col :span="6">
                          <a-form-item>
                            <label :class="'filled'"> Cidade </label>
                            <a-input
                              class="travel-input"
                              placeholder="Cidade"
                              v-decorator="[
                                `contact_city_${contact.id}`,
                                {
                                  initialValue: contact.city,
                                },
                              ]"
                            >
                              <a-icon slot="prefix" type="field-svg" />
                            </a-input>
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-col>

                    <a-col :span="2">
                      <a-button
                        class="mt-10"
                        size="small"
                        type="danger"
                        shape="circle"
                        icon="delete"
                        v-if="contactRows.length == contact.id"
                        @click="removeRow(contactRows, contact.id)"
                      >
                      </a-button>
                    </a-col>
                  </a-row>

                  <div class="a-center">
                    <a-button @click="addRow(contactRows)">
                      Add Contato
                    </a-button>
                  </div>
                </div>

                <a-row class="mt-30" :gutter="20">
                  <a-col :span="12">
                    <div class="mt-10">
                      <h3 class="h3 title mb-20">Telefones</h3>
                      <a-row
                        v-for="(phone, index) in phoneRows"
                        :key="index"
                        :gutter="20"
                      >
                        <a-col :span="18">
                          <a-form-item>
                            <label :class="'filled'"> Telefone/Celular </label>
                            <a-input
                              class="travel-input"
                              placeholder="Telefone/Celular"
                              v-mask="['(##) ####-####', '(##) # ####-####']"
                              v-decorator="[
                                `phone_${phone.id}`,
                                {
                                  initialValue: phone.number,
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Obrigatório',
                                    },
                                  ],
                                },
                              ]"
                            >
                              <a-icon slot="prefix" type="field-svg" />
                            </a-input>
                          </a-form-item>
                        </a-col>

                        <a-col :span="2">
                          <a-button
                            class="mt-10"
                            size="small"
                            type="danger"
                            shape="circle"
                            icon="delete"
                            v-if="phoneRows.length == phone.id"
                            @click="removeRow(phoneRows, phone.id)"
                          >
                          </a-button>
                        </a-col>
                      </a-row>

                      <div class="a-center">
                        <a-button @click="addRow(phoneRows)">
                          Add telefone
                        </a-button>
                      </div>
                    </div>
                  </a-col>

                  <a-col :span="12">
                    <div class="mt-10">
                      <h3 class="h3 title mb-20">E-mails</h3>
                      <a-row
                        v-for="(email, index) in emailRows"
                        :key="index"
                        :gutter="20"
                      >
                        <a-col :span="18">
                          <a-form-item>
                            <label :class="'filled'"> E-mail </label>
                            <a-input
                              class="travel-input"
                              placeholder="E-mail"
                              v-decorator="[
                                `email_${email.id}`,
                                {
                                  initialValue: email.value,
                                  rules: [
                                    {
                                      type: 'email',
                                      message: 'Escreva um e-mail válido',
                                    },
                                    {
                                      required: true,
                                      message: 'Obrigatório',
                                    },
                                  ],
                                },
                              ]"
                            >
                              <a-icon slot="prefix" type="field-svg" />
                            </a-input>
                          </a-form-item>
                        </a-col>

                        <a-col :span="2">
                          <a-button
                            class="mt-10"
                            size="small"
                            type="danger"
                            shape="circle"
                            icon="delete"
                            v-if="emailRows.length == email.id"
                            @click="removeRow(emailRows, email.id)"
                          >
                          </a-button>
                        </a-col>
                      </a-row>

                      <div class="a-center">
                        <a-button @click="addRow(emailRows)">
                          Add e-mail
                        </a-button>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </a-tab-pane>

              <a-tab-pane key="2" forceRender="true">
                <template slot="tab">
                  <a-tooltip placement="right">
                    <template slot="title"> Dados bancários </template>
                    <a-icon type="bank" />
                  </a-tooltip>
                </template>

                <div class="mt-10">
                  <h3 class="h3 title mb-20">Contas Bancárias</h3>
                  <a-row
                    v-for="(bank, index) in bankRows"
                    :key="index"
                    :gutter="20"
                  >
                    <a-col :span="22">
                      <BankData
                        :form="form"
                        :bankId="bank.id"
                        :bankData="bank"
                      />
                    </a-col>
                    <a-col :span="2">
                      <a-button
                        class="mt-10"
                        size="small"
                        type="danger"
                        shape="circle"
                        icon="delete"
                        v-if="bankRows.length == bank.id"
                        @click="removeBank(bank.id)"
                      >
                      </a-button>
                    </a-col>
                  </a-row>
                  <div class="a-center mt-20">
                    <a-button @click="addBank"> Add conta bancária </a-button>
                  </div>
                </div>
              </a-tab-pane>

              <a-tab-pane key="3" forceRender="true">
                <template slot="tab">
                  <a-tooltip>
                    <template slot="title"> Notas </template>
                    <a-icon type="file-text" />
                  </a-tooltip>
                </template>
                <h3 class="h3 title mb-20">Notas</h3>
                <div style="padding: 0px">
                  <Notes
                    module="product-supplier"
                    :moduleId="productSupplier.id"
                  />
                </div>
              </a-tab-pane>
            </a-tabs>

            <div style="float: left: width: 100%; clear: both"></div>
          </a-row>
        </a-col>
      </a-row>

      <a-button
        size="large"
        type="primary"
        html-type="submit"
        :loading="loading"
        style="float: right"
      >
        SALVAR ALTERAÇÕES
      </a-button>
    </a-form>

    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import companyBranchMixins from "@/mixins/company-branches/companyBranchMixins.js";
import EditProductSupplierDefaultData from "@/components/product-suppliers/forms/EditProductSupplierDefaultData.vue";
import Notes from "@/components/general/Notes.vue";
import BankData from "@/components/general/BankData.vue";

export default {
  components: { EditProductSupplierDefaultData, Notes, BankData },
  mixins: [companyBranchMixins],
  data() {
    return {
      form: this.$form.createForm(this),
      productSupplier: {},
      showCreateCompanyBranch: false,
      loading: false,
      bankRows: [],
      contactRows: [],
      emailRows: [],
      phoneRows: [],
      jobs: [
        {
          label: "Agente",
          value: "Agente",
        },
        {
          label: "Consultor",
          value: "Consultor",
        },
        {
          label: "Coordenador de Vendas",
          value: "Coordenador de Vendas",
        },
        {
          label: "Customer Sucess",
          value: "Customer Sucess",
        },
        {
          label: "Financeiro",
          value: "Financeiro",
        },
        {
          label: "Gerente",
          value: "Gerente",
        },
        {
          label: "Gerente de Conta",
          value: "Gerente de Conta",
        },
        {
          label: "Pós Vendas",
          value: "Pós Vendas",
        },
        {
          label: "Reservas",
          value: "Reservas",
        },
      ],
      days: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      invoiceTypes: [
        {
          label: "Semanal",
          value: "Semanal",
        },
        {
          label: "Quinzenal",
          value: "Quinzenal",
        },
        {
          label: "Mensal",
          value: "Mensal",
        },
        {
          label: "Diferenciado (Dia útil/Vencimento)",
          value: "Diferenciado (Dia útil/Vencimento)",
        },
      ],
    };
  },
  beforeMount() {
    this.getProductSupplier();
  },
  methods: {
    addBank() {
      this.bankRows.push({
        id: this.bankRows.length + 1,
        name: undefined,
        agency: undefined,
        account: undefined,
        digit: undefined,
        pix: undefined,
      });
    },
    removeBank(id) {
      for (var i in this.bankRows) {
        if (this.bankRows[i].id == id) {
          this.bankRows.splice(i, 1);
          break;
        }
      }
    },
    addRow(array) {
      array.push({
        id: array.length + 1,
      });
    },
    removeRow(array, id) {
      for (var i in array) {
        if (array[i].id == id) {
          array.splice(i, 1);
          break;
        }
      }
    },
    getProductSupplier() {
      this.$http
        .post(`/product-supplier/details?id=${this.$route.params.id}`)
        .then(({ data }) => {
          this.productSupplier = data;
          document.title = `${data.id} - ${data.trading_name} - HAYA`;

          setTimeout(() => {
            this.form.setFieldsValue(data);

            setTimeout(() => {
              this.form.setFieldsValue({
                category: JSON.parse(data.category),
                product_supplier_ids: data.product_supplier_ids
                  ? JSON.parse(data.product_supplier_ids)
                  : [],
                invoice_closing_day: data.invoice_closing_day,
                invoice_payment_day: data.invoice_payment_day,
              });
            }, 80);

            this.bankRows = JSON.parse(data.bank_accounts);
            this.contactRows = JSON.parse(data.contacts);
            this.phoneRows = JSON.parse(data.phones);
            this.emailRows = JSON.parse(data.emails);
          }, 100);
        })
        .catch(({ response }) => {
          response;
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.id = this.productSupplier.id;
        values.user_id = this.productSupplier.user.id;
        values.category = JSON.stringify(values.category);
        values.product_supplier_ids = JSON.stringify(
          values.product_supplier_ids
        );

        this.bankRows.forEach((bank) => {
          bank.name = this.form.getFieldValue(`bank_name_${bank.id}`);
          bank.agency = this.form.getFieldValue(`bank_${bank.id}_agency`);
          bank.account = this.form.getFieldValue(`bank_${bank.id}_account`);
          bank.digit = this.form.getFieldValue(`bank_${bank.id}_account_digit`);
          bank.pix = this.form.getFieldValue(`bank_${bank.id}_pix`);
        });

        this.contactRows.forEach((contact) => {
          contact.first_name = this.form.getFieldValue(
            `contact_first_name_${contact.id}`
          );
          contact.last_name = this.form.getFieldValue(
            `contact_last_name_${contact.id}`
          );
          contact.email = this.form.getFieldValue(
            `contact_email_${contact.id}`
          );
          contact.birthday = this.form.getFieldValue(
            `contact_birthday_${contact.id}`
          );
          contact.phone = this.form.getFieldValue(
            `contact_phone_${contact.id}`
          );
          contact.job = this.form.getFieldValue(`contact_job_${contact.id}`);
          contact.city = this.form.getFieldValue(`contact_city_${contact.id}`);
        });

        this.phoneRows.forEach((phone) => {
          phone.number = this.form.getFieldValue(`phone_${phone.id}`);
        });

        this.emailRows.forEach((email) => {
          email.value = this.form.getFieldValue(`email_${email.id}`);
        });

        values.bank_accounts = JSON.stringify(this.bankRows);
        values.contacts = JSON.stringify(this.contactRows);
        values.phones = JSON.stringify(this.phoneRows);
        values.emails = JSON.stringify(this.emailRows);

        values.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        console.log(err, values);

        if (!err) {
          this.loading = true;
          this.$http
            .post("/product-supplier/update", values)
            .then(({ data }) => {
              data;
              this.$message.success("Atualizado com sucesso!");
              this.loading = false;
            })
            .catch(({ res }) => {
              res;
              this.$message.error("Não foi possível atualizar.");
              this.loading = false;
            });
        } else {
          this.$message.warning("Alguns campos ainda não foram preenchidos.");
        }
      });
    },
  },
};
</script>
